import React, { useEffect, useState } from "react";
import Header from "../components/header/header";
import "./home.css";
import AllCards from "../components/allCards/allCards";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer/footer";
import { Helmet } from "react-helmet";

export default function Home(props) {
  const navigate = useNavigate();
  let { date } = useParams();
  // const

  var today = new Date();
  var dd = today.getDate()-1;

  var mm = today.getMonth()+1; 
  var yyyy = today.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 

  if(mm<10) 
  {
      mm='0'+mm;
  } 
  today = dd+'-'+mm+'-'+yyyy;
  console.log(today);
  
  if (!date) {
    date = props.date;
  }
  if(!date){
    date=today
    // date='06-01-2022'
  }


  const [actualdate, setActualdate] = useState(date);

  useEffect(() => {
    setActualdate(date);
   
  }, [date]);
  navigate(0);

  return (
   <>
      <Helmet>
          <meta name={"keywords"} content={`cricket match on ${actualdate},daily cricket score on ${actualdate},highlights of cricket match on ${actualdate},match highlights on ${actualdate},cricket matches on, daily cricket score, day night cricket, highlights of day, match highlights,`} />
          
      </Helmet>
      <div className="wraper">
        <Header />
        <div className="date">
          <span className="span-date">{actualdate}</span>
        </div>
        <AllCards date={actualdate} />
        <Footer />
      </div>
     </>
  );
}
