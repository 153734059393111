import './App.css';
// import {BrowserRouter,Routes,Route} from 'react-router-dom'
// import Home from './pages/home';
// import OneMatch from './pages/oneMatch/oneMatch';
// import Calender from './components/calender/calender';
// import AboutUs from './pages/contactus/aboutUs';
import Main from './pages/main';
import React, { Component }  from 'react';

function App() {

  
  return (
 <>
 <Main/>
 </>
  );
}

export default App;
